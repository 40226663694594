/* globals KXL */
KXL.module('HeaderApp.Header', function(Header, KXL, Backbone, Marionette, $, _) {
	var $bodyVeil = $('#kx-body-veil');

	// Build the collection used to build the sub menu items for a given game.
	// TODO rtomaselli: not sure I like this sitting in the view code. Leaving
	// until the path becomes clear...
	/* The staticSubMenu model/collections are only for header game menu right now */

	var localStaticSubMenuItem = KXL.Entities.Model.extend({
		defaults: {
			id: '',
			url: '',
			text: '',
			className: 'kx-game-subflyout-item-text'
		}
	});

	var StaticSubMenuItems = KXL.Entities.Collection.extend({
		model: localStaticSubMenuItem
	});

	/* Nav Bar */
	Header.View = KXL.Components.Layout.extend({
		playersOnline: null,
		template: Templates['header/header'],
		className: 'kx-header',
		regions: {
			gamesMenu: '.kx-header-games-menu',
			playersOnlineRegion: '.kx-header-players-online-region',
			contextNavRegion: '.kx-header-context-nav',
			bannersRegion: '.kx-banners-region'
		},
		triggers: {
			'click .kx-header-logo': 'home:click'
		},
		events: {
			'click .kx-user-notifications-wrapper': 'notificationsWrapperClicked'
		},
		ui: {
			userMenu: '.kx-user-menu-wrapper'
		},
		initialize: function () {
			this.listenTo(KXL.appModel, 'change:context', this.setupContextNav);
		},
		setPlayersOnlineTop: function (top) {
			if (this.playersOnlineRegion) {
				this.playersOnlineRegion.ensureEl();
				this.playersOnlineRegion.$el.css('top', top);
			}
		},
		setupContextNav: function () {
			var self = this;
			if (KXL.context) {
				var staticGame = KXL.staticGames.get(KXL.context.get('urlName')),
					contextNavItems = staticGame && staticGame.get('contextNavItems'),
					contextNavIcon = staticGame && staticGame.get('contextNavIcon');
			}

			if (!contextNavItems) {
				contextNavItems = [
					{
						id: 'support',
						text: 'header.context.support',
						url: 'https://support.kixeye.com'
					}
				];
			}

			function completeSetup() {
				self.stopListening(KXL.vent, 'auth:changed beta:code:success info:game:load', self.setupContextNav);
				self.listenToOnce(KXL.vent, 'auth:changed beta:code:success info:game:load', self.setupContextNav);

				// Some nav items can only be displayed if the current user has a game instance for the selected context
				if (KXL.context && (!KXL.currentUserGames || !KXL.currentUserGames.get(KXL.context.id))) {
					contextNavItems = _.reject(contextNavItems, function (navItem) {
						return navItem.userGameRequired;
					});
				}
				if (KXL.currentUserGames) {
					self.stopListening(KXL.currentUserGames, 'add remove', self.setupContextNav);
					self.listenToOnce(KXL.currentUserGames, 'add remove', self.setupContextNav);
				}
				var lastContextItem;
				contextNavItems = new Backbone.Collection(contextNavItems);
				contextNavItems = _.extend(contextNavItems, new Backbone.PickThis.SingleSelect(contextNavItems, 'context'));
				// If this flag is set to false, users are not supposed to be able to play the games
				if (!KXL.config.SHOW_GAMES) {
					contextNavItems.remove(contextNavItems.get('play'));
				}
				contextNavItems.on('deselect:one', function (contextNavItem) {
					lastContextItem = contextNavItem;
				});
				contextNavItems.on('select:one', function (contextNavItem) {
					// only need to do this if the current page is not the same as the selected context
					if (KXL.request('get:current:page') !== contextNavItem.id) {
						var url = contextNavItem.get('url'),
							selectedPage = KXL.appModel.get('pages').get(contextNavItem.id),
							navUrl;
						if (selectedPage && selectedPage.get('route')) {
							navUrl = selectedPage.get('route');
						}

						switch (contextNavItem.id) {
							case 'home':
								if (KXL.homeRegion.currentView) {
									KXL.navigate(navUrl);
									KXL.execute('page:show', 'home');
								} else {
									KXL.execute('home:show');
								}
								break;
							case 'profile':
								if (KXL.profileRegion.currentView) {
									KXL.navigate(navUrl);
									KXL.execute('page:show', 'profile');
								} else {
									KXL.execute('home:show');
								}
								break;
							case 'guide':
								if (KXL.guideRegion.currentView) {
									KXL.navigate(navUrl);
									KXL.execute('page:show', 'guide');
								} else {
									if (KXL.context.get('urlName') === 'battlepirates') {
										KXL.asyncRequest('async:load:module', 'guide').then(function () {
											KXL.execute('guide:show', KXL.context.get('urlName'));
										});
									} else {
										KXL.asyncRequest('async:load:module', 'game/guide').then(function () {
											KXL.execute('oldGuide:show', KXL.context.get('urlName'));
										});
									}
								}
								break;
							case 'forum':
								window.open(url);
								break;
							case 'support':
								KXL.navigate(navUrl || url, { target: 'kxl-support' });
								if (lastContextItem) {
									// So that the support link doesn't sit there selected... select the previous context item
									contextNavItems.select(lastContextItem);
								}
								break;
							case 'play':
								var staticGame = KXL.staticGames.get(KXL.context.get('urlName'));
								if (staticGame && staticGame.has('steamUrl')) {
									KXL.execute('game:navigate:steam', KXL.context.get('urlName'));
								} else if (staticGame && !staticGame.get('autoRegistration') && !KXL.currentUser) {
									KXL.execute('game:register', KXL.context.get('urlName'));
									// the register action just brings up a dialog, we should still have the previous item selected
									contextNavItems.select(lastContextItem);
								} else if (KXL.playRegion.currentView) {
									KXL.navigate(navUrl);
									KXL.execute('page:show', 'play');
								} else {
									if (KXL.currentUser) {
										KXL.execute('game:play:load', KXL.context.get('urlName'));
									} else {
										var currentGameUrlName = KXL.context && KXL.context.get('urlName');
										if (currentGameUrlName) {

											KXL.execute('show:register:dialog', {
												step: KXL.AuthApp.RegisterSteps.IMPORT_FB_GAMES_PROMPT,
												startNowUrlName: currentGameUrlName,
												registrationHeaderText: KXL.i18n.t('auth.autoStart.registrationHeaderText')
											});
										}
									}
								}
								break;
							default:
								if (KXL.gameRegion.currentView) {
									KXL.navigate(navUrl);
									KXL.execute('page:show', 'game');
								} else {
									KXL.execute('game:show', KXL.context.get('urlName'), 'home');
								}
						}
					}
				});
				// So that we don't stack up listeners...
				self.stopListening(KXL.appModel.get('pages'));
				self.listenTo(KXL.appModel.get('pages'), 'select:one', function (selectedModel) {
					if (contextNavItems.get(selectedModel.id)) {
						contextNavItems.select(contextNavItems.get(selectedModel.id));
					} else {
						contextNavItems.deselect();
					}
				});
				self.contextNavRegion.show(
					new ContextNavItems({
						collection: contextNavItems,
						icon: contextNavIcon
					})
				);
				var selectedPage = KXL.appModel.get('pages').selected,
					currentNavItem = selectedPage && contextNavItems.get(selectedPage.id);
				if (currentNavItem) {
					contextNavItems.select(currentNavItem);
				}
			}

			if (KXL.context && KXL.context.get('inviteOnly')) {
				if (!KXL.currentUser) {
					contextNavItems = _.reject(contextNavItems, function (navItem) {
						if (navItem.betaAccessRequired) {
							return true;
						}
					});
					completeSetup();
				} else {
					if (KXL.atom.get('game:isopen')) {
						$.when(
							KXL.asyncRequest('game:is:user:allowed'),
							KXL.asyncRequest('async:load:module', 'game/beta_code')
						).then(function (userIsAllowedResult) {
							var allowed = userIsAllowedResult[0],
								gameInviteModel = userIsAllowedResult[1];
							if (!allowed) {
								contextNavItems = _.reject(contextNavItems, function (navItem) {
									if (navItem.betaAccessRequired) {
										return true;
									}
								});
								contextNavItems.push({
									id: 'enter-beta-key',
									url: '',
									text: function () {
										return new KXL.GamesApp.BetaCode.EnterCode({
											tagName: 'li',
											model: gameInviteModel
										});
									}
								});
							}
							completeSetup();
						});
					} else {
						completeSetup();
					}
				}
			} else if (!KXL.currentUser) {
				// Some nav items can only be displayed in a logged in state
				contextNavItems = _.reject(contextNavItems, function (navItem) {
					if (navItem.loggedInRequired) {
						return true;
					}
				});
				completeSetup();
			} else {
				completeSetup();
			}
		},
		onRender: function () {
			this.playersOnline = KXL.request('new:playersonline:view');
			this.playersOnlineRegion.show(this.playersOnline);
			this.setupContextNav();
		}
	});

	Header.NavToggleView = KXL.Components.Layout.extend({
		template: Templates['header/header-nav-toggle'],
		className: 'kx-nav-toggle kx-menu-wrapper',
		triggers: {
			'click .kx-main-menu-btn': 'clicked'
		},
		toggleActive: function (isActive) {
			this.$el.find('.kx-main-menu-btn').toggleClass('kx-active', isActive);
		}
	});

	Header.ContextChangeOverlay = Marionette.Layout.extend({
		template: Templates['header/header-context-change'],
		className: 'kx-header-context-change',
		regions: {
			cancelRegion: '.kx-cancel-region',
			continueRegion: '.kx-continue-region'
		},
		serializeData: function () {
			return {
				currentContextName: this.options.currentContext.get('name'),
				newContextName: this.options.newContext.get('name')
			}
		},
		onRender: function () {
			var cancelBtn = new KXL.Components.Button.View({
					label: KXL.i18n.t('header.context.change.cancel'),
					theme: 'kx-alt23'
				}),
				continueBtn = new KXL.Components.Button.View({
					label: KXL.i18n.t('header.context.change.continue'),
					theme: 'kx-alt23'
				}),
				self = this;
			cancelBtn.on('click', function () {

				self.options.defer.reject(false);
			});
			continueBtn.on('click', function () {
				self.options.defer.resolve(true);
			});
			this.cancelRegion.show(cancelBtn);
			this.continueRegion.show(continueBtn);
		}
	});

	Header.NavRibbonView = KXL.Components.Layout.extend({
		template: Templates['header/header-nav-ribbon'],
		className: 'kx-nav-ribbon',
		regions: {
			overlay: '.kx-nav-ribbon-overlay-region',
			desktopGameList: '.kx-nav-ribbon-desktopgamelist-region',
			mobileGameList: '.kx-nav-ribbon-mobilegamelist-region',
			communityList: '.kx-nav-ribbon-communitylist-region',
			corpListRegion: '.kx-nav-ribbon-corplist-region'
		},
		onRender: function() {
			var self = this;

			this.desktopGameList.show(new Header.NavGameListView({ type: 'desktop' }));
			this.mobileGameList.show(new Header.NavGameListView({ type: 'mobile' }));

			function showCommunityList() {
				var entries = [{
					className: "kx-game-subflyout-item-text",
					id: "support",
					subnavClassName: "kx-game-nav-item-text",
					subnavText: "game.nav.support",
					text: KXL.i18n.t('header.commSupport'),
					href: "https://www.kixeye.com/support"
				}];

				self.communityList.show(
					new Header.NavCommunityListSubmenuView({
						collection: new StaticSubMenuItems(entries)
					})
				);
			}

			function showCorpList() {
				var entries = [];

				entries.push(
					{
						id: 'about',
						text: KXL.i18n.t('header.corpAbout'),
						className: 'kx-corp-nav-item-text',
						href: 'http://corp.kixeye.com/',
						target: '_blank'
					},
					{
						id: 'careers',
						text: KXL.i18n.t('header.corpCareers'),
						className: 'kx-corp-nav-item-text',
						href: 'http://corp.kixeye.com/#careers',
						target: '_blank'
					}
				);
				self.corpListRegion.show(
					new Header.NavCorpListSubmenuView({
						collection: new StaticSubMenuItems(entries)
					})
				);
			}

			if (self.communityList) {
				showCommunityList();
			}
			if (self.corpListRegion) {
				showCorpList();
			}

			this.overlay.on('show', function () {
				self.overlay.$el.show();
			});
			this.overlay.on('close', function () {
				self.overlay.$el.hide();
			});
		}
	});

	Header.NavGameListView = KXL.Components.Layout.extend({
		regions: {
			gameListLinks: '.kx-nav-ribbon-gamelist-links-region'
		},
		ui: {
			desktopList: '.kx-desktop-list',
			iconToggle: '.kx-nav-ribbon-gamelist-icon'
		},
		events: {
			'click .kx-desktop-list': 'showMobileGames',
			'click .kx-mobile-list': 'showDesktopGames'
		},
		template: Templates['header/header-nav-gamelist'],
		className: 'kx-nav-ribbon-gamelist',
		showMobileGames: function () {
			this.gameListLinks.reset();
			this.gameListLinks.show(
				new Header.NavGameListMobileView(
					{
						collection: new Backbone.FilteredCollection(
							null,
							{
								collection: KXL.staticGames,
								collectionFilter: function (game) {
									return game.get('isMobile');
								}
							}
						)
					}
				)
			);
		},
		showDesktopGames: function () {
			this.gameListLinks.reset();
			this.gameListLinks.show(
				new Header.NavGameListDesktopView(
					{
						collection: new Backbone.FilteredCollection(
							null,
							{
								collection: KXL.staticGames,
								collectionFilter: function (game) {
									return !game.get('isMobile');
								}
							}
						)
					}
				)
			);
		},
		onRender: function() {
			if (this.options && this.options.type) {
				if (this.options.type === 'desktop') {
					this.showDesktopGames();
				} else {
					this.showMobileGames();
				}
			}
		}
	});

	Header.NavGameListViewItem = Marionette.ItemView.extend({
		template: Templates['header/header-nav-gamelist-item'],
		tagName: 'li',
		triggers: {
			'click .kx-nav-ribbon-gamelist-list-link': 'clicked'
		},
		onClicked: function () {
			KXL.vent.trigger('game:clicked', this);
			this.trigger('clicked');
			KXL.appModel.set('navRibbonShowing', false);
		},
		initialize: function () {
			var self = this;
			if (this.model.get('isMobile') ||
				this.model.get('supportsMobile'))
			{
				this.$el.addClass('kx-mobile');
			}
		}
	});

	Header.NavGameListDesktopView = Marionette.CompositeView.extend({
		template: Templates['header/header-nav-gamelist-desktop'],
		itemView: Header.NavGameListViewItem,
		itemViewContainer: '.kx-nav-ribbon-gamelist-list',
		className: 'kx-nav-ribbon-gamelist-links-desktop'
	});

	Header.NavGameListMobileView = Marionette.CompositeView.extend({
		template: Templates['header/header-nav-gamelist-mobile'],
		itemView: Header.NavGameListViewItem,
		itemViewContainer: '.kx-nav-ribbon-gamelist-list',
		className: 'kx-nav-ribbon-gamelist-links-mobile'
	});

	Header.NavCommunityListItemView = Marionette.ItemView.extend({
		template: Templates['header/header-nav-communitylist-item'],
		tagName: 'li',
		triggers: {
			'click': 'clicked'
		}
	});

	Header.NavCommunityListSubmenuView = Marionette.CompositeView.extend({
		template: Templates['header/header-nav-communitylist'],
		itemViewContainer: '.kx-submenu-flyout',
		itemView: Header.NavCommunityListItemView,
		itemViewEventPrefix: 'sub:menu:item',
		className: 'kx-header-nav-communitylist',
		onSubMenuItemClicked: function (submenu) {
			KXL.vent.trigger('game:section:clicked', this, submenu);
			this.trigger('game:section:clicked', this, submenu);
			KXL.appModel.set('navRibbonShowing', false);
		}
	});

	Header.NavCorpListItemView = Marionette.ItemView.extend({
		template: Templates['header/header-nav-corplist-item'],
		tagName: 'li',
		triggers: {
			'click': {
				event: 'clicked',
				preventDefault: false,
				stopPropagation: true
			}
		}
	});

	Header.NavCorpListSubmenuView = Marionette.CompositeView.extend({
		template: Templates['header/header-nav-corplist'],
		itemViewContainer: '.kx-submenu-flyout',
		itemView: Header.NavCorpListItemView,
		itemViewEventPrefix: 'sub:menu:item',
		className: 'kx-header-nav-corplist',
		onSubMenuItemClicked: function (submenu) {
			KXL.appModel.set('navRibbonShowing', false);
		}
	});

	/* Context Nav Templates */
	var ContextNavItem = Marionette.ItemView.extend({
		template: Templates['header/header-context-nav-item'],
		tagName: 'li',
		className: function () {
			var className = ['kx-context-nav-item kx-game-nav-item-text'];
			if (this.model) {
				if (this.model.id) {
					className.push(this.model.id);
				}
				if (this.model.get('userCanClose')) {
					className.push('kx-user-can-close');
				}
			}
			return className.join(' ');
		},
		triggers: {
			'click': 'clicked',
			'click .kx-context-item-close': 'close'
		},
		modelEvents: {
			'selected:context': function () {
				this.$el.addClass('kx-active');
			},
			'deselected:context': function () {
				this.$el.removeClass('kx-active');
			}
		}
	});

	var PlayContextNavItem = ContextNavItem.extend({
		modelEvents: {
			'selected:context': function () {
				this.$el.addClass('kx-active');
				this.$el.toggleClass(
					'kx-loaded',
					this.collection.selected === this.model &&
					KXL.request('user:playing')
				);
			},
			'deselected:context': function () {
				this.$el.removeClass('kx-active');
			}
		},
		serializeData: function () {
			return {
				text: (!KXL.currentUser && this.model.has('textLoggedOut') ? this.model.get('textLoggedOut') : this.model.get('text'))
			};
		}
	});

	var ContextNavItems = Marionette.CompositeView.extend({
		template: Templates['header/header-context-nav'],
		className: 'kx-context-nav',
		ui: {
			'icon': '.kx-context-icon'
		},
		buildItemView: function(model, ItemViewType, itemViewOptions) {
			var options = { model: model, collection: this.collection };
			if (itemViewOptions) {
				options = _.extend(itemViewOptions, options);
			}
			// Allows to replace the ContextNavItem with a custom view
			if (typeof model.get('text') === 'function') {
				return model.get('text')(options);
			}

			if (model.id === 'play') {
				ItemViewType = PlayContextNavItem;
			}
			return new ItemViewType(options);
		},
		itemView: ContextNavItem,
		itemViewEventPrefix: 'context:nav:item',
		itemViewContainer: '.kx-context-nav-items',
		onContextNavItemClicked: function(navItem) {
			this.collection.select(navItem.model);
		},
		onContextNavItemClose: function (navItem) {
			KXL.execute('page:close', navItem.model.id);
			navItem.$el.removeClass('kx-loaded');
			// If they are currently on the play page, select the game home
			if (navItem.model === this.collection.selected) {
				this.collection.select(this.collection.get('game'));
			}
		},
		onRender: function () {
			if (this.options.icon) {
				this.ui.icon.css &&
				this.ui.icon.css('background-image', 'url('.concat(KXL.config.STATIC_BASE_URL, this.options.icon, ')'));
				this.ui.icon.show && this.ui.icon.show();
			} else {
				this.ui.icon.hide && this.ui.icon.hide();
			}
		}
	});

	/* User Menu */
	Header.UserMenuItem = KXL.Components.Layout.extend({
		template: Templates['header/_header-user-menu-item'],
		tagName: 'li',
		triggers: {
			'mouseover': 'hovered'
		},
		events: {
			'click .kx-menu-item-lnk': 'menuItemClicked'
		},
		regions: {
			countRegion: '.kx-menu-item-count',
			buttonRegion: '.kx-menu-btn',
			flyoutRegion: '.kx-megaflyout-inner'
		},
		initialize: function(options) {
			this.options = options;
		},
		onRender: function () {
			if (this.options.flyoutView) {
				this.flyoutRegion.show(this.options.flyoutView);
			}
		},
		menuItemClicked: function () {
			this.trigger('user:menu:clicked', this);
		},

		updateNotificationCount: function (count) {
			var self = this;

			if (count === 0) {
				self.$el.find('.kx-notify-count').hide();
			} else {
				self.$el.find('.kx-notify-count').text(count).show();
			}
		},

		updateFriendRequestsCount: function (count) {
			var self = this;
			if (count === 0) {
				self.$el.find('.kx-notify-count').hide();
			} else {
				self.$el.find('.kx-notify-count').text(count).show();
			}
		},
		serializeData: function () {
			return {
				buttonClassName: this.options.buttonClassName
			};
		}
	});

	Header.UserMenu = Marionette.CollectionView.extend({
		itemView: Header.UserMenuItem,
		itemViewEventPrefix: 'menu:item',
		className: 'kx-menu',
		tagName: 'ul'
	});

	Header.AccountInfoView = KXL.Components.Layout.extend({
		template: Templates['header/header-account-info'],
		model: KXL.Entities.User,
		ui: {
			'menuWrapper': '.kx-account-info-menu-wrapper'
		},
		triggers: {
			'click .kx-header-account': 'account:click'
		},
		events: {
			'click .kx-menu-btn-open': 'toggleRibbon'
		},
		regions: {
			avatar: '.kx-header-account-avatar',
		},
		initialize: function () {
			this.model.on('change', this.render);
			this.listenTo(KXL.vent, 'user:menu:clicked nav:veil:off', function () {
				if (this.$el) {
					this.$('.kx-menu-wrapper').removeClass('kx-active');
				}
			});
		},
		toggleRibbon: function () {
			var isShown = $('.accountInfoRibbon').is(':visible');
			if (!isShown) {
				KXL.appModel.set('navRibbonShowing', false);
				KXL.request('banner:create',
					{
						bannerId: 'accountInfoRibbon',
						view: new Header.AccountInfoRibbonView({
							model: KXL.currentUser,
							className: 'kx-header-user-controls-wrapper',
							API_URL: KXL.config.API_URL
						})
					}
				);
			} else {
				KXL.request('banner:remove', 'accountInfoRibbon');
			}
			this.ui.menuWrapper.toggleClass('kx-active');
		},
		onRender: function () {
			var self = this;
			this.avatar.show(
				KXL.request(
					'new:avatar:view',
					this.model.id,
					{
						model: this.model,
						size: 'small',
						color: KXL.Components.Avatar.borderColors.GREY
					}
				)
			);
		},
		serializeData: function () {
			return {
				user: this.model.toJSON()
			};
		}
	});
	Header.AccountInfoRibbonView = KXL.Components.Layout.extend({
		template: Templates['header/header-account-info-ribbon'],
		model: KXL.Entities.User,
		className: 'kx-account-ribbon',
		events: {
			'click .kx-header-logout-btn': function () {
				$('.kx-menu-wrapper').removeClass('kx-active');
				KXL.request('banner:remove', 'accountInfoRibbon');
				KXL.execute('logout');
			},
			'click .kx-settings-btn': function () {
				$('.kx-menu-wrapper').removeClass('kx-active');
				KXL.request('banner:remove', 'accountInfoRibbon');
				if (!KXL.request('auth:register:deferred:gate', { referrer: 'header-account-settings' })) {
					KXL.execute('settings:show');
				}
			}
		},
		regions: {
			localesRegion: '.kx-header-locales-region'
		},
		initialize: function () {
			this.model.on('change', this.render);
		},
		onRender: function () {
			var self = this;
			this.localesRegion.show(
				KXL.request('new:locale:selector:view', {
					locales: KXL.appModel.get('supportedLocales'),
					locale: KXL.config.locale
				})
			);
		},
		serializeData: function () {
			return {
				user: this.model.toJSON()
			};
		}
	});
	Header.LoginJoinView = Marionette.ItemView.extend({
		template: Templates['header/header-login-join'],
		triggers: {
			'click .kx-header-login-btn': 'login:click',
			'click .kx-header-join-btn': 'join:click'
		}
	});
	Header.SimpleRibbon = Marionette.ItemView.extend({
		template: Templates['header/header-simple-ribbon'],
		className: 'kx-simple-ribbon',
		triggers: {
			'click': 'click'
		},
		serializeData: function () {
			return {
				message: this.options.message
			};
		}
	});
});
